<script lang="ts">
    import '../StyleCss.css';
    import { setContext } from 'svelte';
    import { writable } from 'svelte/store';
    import { browser } from '$app/environment';
    import { setupBreadcrumbs } from '$lib/components/breadcrumbs';

    // https://daisyui.com/docs/themes/

    setupBreadcrumbs();

    const theme = writable(browser ? localStorage.getItem('theme') ?? 'system' : 'system');

    theme.subscribe((value) => {
        if (!browser) {
            return;
        }
        if (value === 'dark') {
            document.documentElement.setAttribute('data-theme', 'dark');
            localStorage.setItem('theme', 'dark');
        } else if (value === 'system') {
            localStorage.setItem('theme', 'system');
            window.matchMedia('(prefers-color-scheme: dark)').matches
                ? document.documentElement.setAttribute('data-theme', 'dark')
                : document.documentElement.setAttribute('data-theme', 'light');
        } else {
            localStorage.setItem('theme', 'light');
            document.documentElement.setAttribute('data-theme', 'light');
        }
    });

    if (browser) {
        // Watch for system preferences when we use system default
        // Note: doesnt update if the value is the same
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            if ($theme === 'system') {
                if (e.matches) {
                    theme.set('dark');
                } else {
                    theme.set('light');
                }
                theme.set('system');
            }
        });
    }

    setContext('theme', theme);
</script>

<div data-name="base">
    <slot />
</div>
